import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout/Layout';
import { Link } from 'gatsby';

export const DataRoomPageTemplate = ({ title, heading, pricing }) => (
  <div className='bg-transparent'>
    <div className='lg:max-w-7xl mx-auto px-4 mt-4'>
      <div className='py-6 text-center'>
        <h1 className='text-lg text-yellow-700 font-semibold tracking-wide uppercase'>
          {heading}
        </h1>
        <h2 className='mt-2 text-6xl font-extrabold tracking-tight text-white'>
          {title}
        </h2>
        <h3 className='mt-4 max-w-2xl mx-auto text-2xl font-normal text-gray-400'>
          We provide easy solutions to energy's big data problem
        </h3>
      </div>
      <div className='text-justify mt-4'>
        <dl className='space-y-0 grid grid-cols-2 gap-x-8 gap-y-10'>
          <div className='flex'>
            <div className='flex-shrink-0'>
              <div className='flex items-center justify-center h-12 w-12 rounded-md bg-white text-yellow-700'>
                <svg
                  className='h-6 w-6'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z'
                  />
                </svg>
              </div>
            </div>
            <div className='ml-4'>
              <dt className='text-lg leading-6 font-medium text-yellow-700'>
                US Offshore and Onshore Data
              </dt>
              <dd className='mt-2 text-base text-gray-400'>
                Browse, export, and visualize data from US public data sources
                covering onshore unconventional to deepwater Gulf of Mexico.
              </dd>
            </div>
          </div>

          <div className='flex'>
            <div className='flex-shrink-0'>
              <div className='flex items-center justify-center h-12 w-12 rounded-md bg-white text-yellow-700'>
                <svg
                  className='h-6 w-6'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  aria-hidden='true'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M13 10V3L4 14h7v7l9-11h-7z'
                  />
                </svg>
              </div>
            </div>
            <div className='ml-4'>
              <dt className='text-lg leading-6 font-medium text-yellow-700'>
                Fast and responsive
              </dt>
              <dd className='mt-2 text-base text-gray-400'>
                No clunky software required. Navigate from the comfort of your
                own browser (you're not used to this, are you?).
              </dd>
            </div>
          </div>

          <div className='flex'>
            <div className='flex-shrink-0'>
              <div className='flex items-center justify-center h-12 w-12 rounded-md bg-white text-yellow-700'>
                <svg
                  className='h-6 w-6'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  aria-hidden='true'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3'
                  />
                </svg>
              </div>
            </div>
            <div className='ml-4'>
              <dt className='text-lg leading-6 font-medium text-yellow-700'>
                Freemium pricing models
              </dt>
              <dd className='mt-2 text-base text-gray-400'>
                Gone are the days of big-oil, care-free budgets. The industry is
                tightening its belt. We're here to keep things moving.
              </dd>
            </div>
          </div>

          <div className='flex'>
            <div className='flex-shrink-0'>
              <div className='flex items-center justify-center h-12 w-12 rounded-md bg-white text-yellow-700'>
                <svg
                  className='h-6 w-6'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  aria-hidden='true'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z'
                  />
                </svg>
              </div>
            </div>
            <div className='ml-4'>
              <dt className='text-lg leading-6 font-medium text-yellow-700'>
                Discussions
              </dt>
              <dd className='mt-2 text-base text-gray-400'>
                Connect with industry peers and discuss the latest oil and gas
                activity.
              </dd>
            </div>
          </div>
        </dl>
      </div>
      <div>
        <p className='py-10 text-4xl font-bold tracking-tight text-white text-center'>
          {pricing.description}
        </p>
        <p className='py-5 pb-10 text-4xl font-bold tracking-tight text-white text-center'>
          while we're currently in beta testing, we are accepting a limited
          number of users. reach out below for general updates and access
          inquiries.
        </p>
        <div className='flex justify-center'>
          <Link
            className='flex w-3/5 justify-center py-3 px-4 text-xl font-semibold border border-transparent rounded-md hover:text-white text-white bg-yellow-700 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
            to='/app'
          >
            explore the data
          </Link>
        </div>
        {/* <Pricing data={pricing.plans} /> */}
      </div>
    </div>
  </div>
);

DataRoomPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
};

const DataRoomPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <DataRoomPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        pricing={frontmatter.pricing}
      />
    </Layout>
  );
};

DataRoomPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default DataRoomPage;

export const dataRoomPageQuery = graphql`
  query DataRoomPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        pricing {
          heading
          description
          plans {
            description
            items
            plan
            price
          }
        }
      }
    }
  }
`;
